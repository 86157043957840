import React from 'react';
import { Link } from 'gatsby';

//styles
import * as styles from './toc.styles.module.scss';
const WalrusIcon = require('../../../static/walrus-3d-no-bg.svg');

interface TOCProps {
  tableOfContent: string;
  lang?: string;
}

const TOC: React.FC<TOCProps> = ({ tableOfContent, lang }) => (
  <nav className={styles.tocContainer}>
    <p className={styles.tocTitle}>
      {`${lang ? 'TABLA DE CONTENIDO' : 'TABLE OF CONTENTS'}`}
    </p>
    <div dangerouslySetInnerHTML={{ __html: tableOfContent }} />
    <Link to='/'>
      <div className={styles.linkHome}>
        <img src={WalrusIcon} alt='Walrus Home Icon' />
        <span>{`${lang ? 'INICIO' : 'HOME'}`}</span>
      </div>
    </Link>
  </nav>
);

export default TOC;
