import React from 'react';
import { graphql, PageProps } from 'gatsby';
import Layout from '../../components/layout/layout';

//Components
import SEO from '../../components/seo/seo';
import Main from '../../components/main/main.component';
// import Aside from '../../components/aside/aside.component';
import Sidebar from '../../components/sidebar/sidebar.component';
import TOC from '../../components/toc/toc.component';
import ContentGrid from '../../components/content-grid/content-grid.component';

//Types
import { MDRemarkTypes as MarkdownProps } from '../../types/allMarkdownRemark.types';

//style
import * as styles from './blog-post.styles.module.scss';
// import SideBar from '../../components/sidebar/sidebar.component';

const BlogPost: React.FC<PageProps<MarkdownProps>> = ({ data }) => {
  const post = data.markdownRemark;

  const {
    title,
    description,
    tags,
    authors,
    date,
    image,
    lang,
  } = post.frontmatter;

  const { slug } = post.fields;

  const metaImage = image ? image.childImageSharp.fluid.src : null;

  const metaImageAlt = image ? `${title} Thumbnail` : null;

  return (
    <Layout>
      <SEO
        title={title}
        description={description}
        image={metaImage}
        imageAlt={metaImageAlt}
        type='article'
        slug={slug}
        lang={lang}
        meta={[
          {
            property: `article:published_time`,
            content: date,
          },
          //Update to profile in future
          {
            property: 'article:author',
            content: authors.map(author => author.id),
          },
          {
            property: 'article:tag',
            content: tags,
          },
        ]}
      />
      <ContentGrid>
        <Main>
          <article
            id={styles.article}
            dangerouslySetInnerHTML={{ __html: post.html }}
          />
        </Main>
        <Sidebar>
          <TOC tableOfContent={post.tableOfContents} lang={lang} />
        </Sidebar>
      </ContentGrid>
    </Layout>
  );
};

export default BlogPost;

export const query = graphql`
  query($slug: String!) {
    markdownRemark(fields: { slug: { eq: $slug } }) {
      html
      tableOfContents(maxDepth: 3, absolute: false)
      fields {
        slug
      }
      frontmatter {
        date
        title
        lang
        description
        authors {
          id
          name
          profile_pic
          socials {
            twitter
            website
          }
        }
        image {
          childImageSharp {
            fluid(maxWidth: 900) {
              src
            }
          }
        }
        tags
      }
    }
  }
`;
